import { render, staticRenderFns } from "./PatientDueReport.vue?vue&type=template&id=5295ad1a&scoped=true&"
import script from "./PatientDueReport.vue?vue&type=script&lang=js&"
export * from "./PatientDueReport.vue?vue&type=script&lang=js&"
import style0 from "./PatientDueReport.vue?vue&type=style&index=0&id=5295ad1a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5295ad1a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBtn,VCard,VCardText,VCol,VCombobox,VContainer,VForm,VRow,VSimpleTable})
